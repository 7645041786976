import { AppBar, Box, Toolbar, Button } from "@mui/material";
import { scroller } from "react-scroll";
import { LogOut } from "./Auth";
import { LoginWGoogle, LogInWithMS } from "./Auth";

const Navbar = (props) => {

    const goToPricing = () => {
        if (window.location !== "localhost:3000") {
            scroller.scrollTo('pricing', {
                duration: 500,
                smooth: true,
            });
        }
    };

    const goToFAQ = () => {
        scroller.scrollTo('FAQ', {
            duration: 500,
            smooth: true,
        });
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: "#88d4f7" }}>
                    <Button style={{ color: "black" }} href="home">Home</Button>
                    <Button style={{ color: "black" }} onClick={goToFAQ}>Usage</Button>
                    <Button style={{ color: "black" }} onClick={goToPricing}>Pricing</Button>
                    <LoginWGoogle />
                    <LogInWithMS />
                    {/* don't do this ^^^ repetition in code is generally frowned upon */}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export const LoggedInNavbar = () => {

    const goToSheets = () => {
        window.location.replace("https://www.google.com/sheets/about/");
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: "#88d4f7" }}>
                    <Button style={{ color: "black" }} href="home">Home</Button>
                    {/* <Button style={{ color: "black" }} onClick={() => {goToSheets()}}>Go to Sheets</Button> */}
                    <LogOut />
                    {/* don't do this ^^^ repetition in code is generally frowned upon */}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navbar;