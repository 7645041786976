const firebaseConfig = {
    apiKey: "AIzaSyAn5Xj_rCr_gNtkvyQqrBM_mRSmNQf11fE",
    authDomain: "documentationapp-8c1f1.firebaseapp.com",
    projectId: "documentationapp-8c1f1",
    storageBucket: "documentationapp-8c1f1.appspot.com",
    messagingSenderId: "104652138565",
    appId: "1:104652138565:web:ff1cbe43dd539977ccdb2c",
    measurementId: "G-JW80SVDGNY"
  };

  export default firebaseConfig;