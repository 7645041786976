import { Button, Card, Grid } from "@mui/material";
import firebaseConfig from './firebaseConfig';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import HomePage from "./pages/HomePage";
import { useState } from 'react';
import React from 'react';

const PublicPage = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const [appUser, setAppUser] = useState(null);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            setAppUser(user.uid);

            // ...
        } else {
            // User is signed out
            // ...

        }
    });

    return (
        appUser ?
            <div className="App">
                <HomePage userID={appUser} />
            </div>
            :
            <div className="App">
                <HomePage />
            </div>
    )
}

export default PublicPage;