import { Card, Grid, Typography } from "@mui/material";
import { useMediaQuery } from 'react-responsive';

const Pricing = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    return (
        <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item lg={12} >
                {isDesktopOrLaptop &&
                    <Card style={{ margin: 30, height: '50vh', width: '60vw' }}>
                        <h1 align="center">Pricing for the App</h1>
                        <Typography fontSize={20}>For individuals: 30 day free trial, then $120 per year subscription
                        </Typography>
                    </Card>
                }
                {isTabletOrMobile &&
                    <Card style={{ margin: 30, height: '75vh', width: '65vw' }}>
                        <h3 align="center">Pricing for the App</h3>
                        <Typography fontSize={15}>For individuals: 30 day free trial, then $120 per year subscription</Typography>
                    </Card>
                }
            </Grid>
        </Grid>
    )
}

export default Pricing;