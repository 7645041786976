import { getAuth, signInWithRedirect, GoogleAuthProvider, signOut, OAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import { Button } from "@mui/material";
import Navbar from "./NavigationBar";
import { useState } from "react";

export const LoginWGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const go = () => {
        signInWithRedirect(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <Button onClick={() => go()}>Sign in with Google</Button>
    )
}

export const CreateNewUser = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const go = () => {
        signInWithRedirect(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <Button style={{ color: "black" }} onClick={() => go()}>Sign in with Google</Button>
    )
}

export const LogOut = () => {
    const auth = getAuth();

    const logout = () => {
        signOut(auth).then(() => {
            window.location.replace("https://documentationapp-8c1f1.web.app/");
        }).catch(() => {

        })
    }

    return (
        <Button onClick={() => logout()}>Log Out</Button>
    )
}

export const LogInWithMS = () => {
    const auth = getAuth();
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('Mail.ReadWrite');
    provider.addScope('User.Read');
    provider.setCustomParameters({
        prompt: "consent",
        // tenant: "the tenant id provided by outlook",
      })

    const go = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a MS Access Token. You can use it to access the MS API.
                const credential = OAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // console.log(token);
                localStorage.setItem("token", token)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
        
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = OAuthProvider.credentialFromError(error);
                console.log(errorCode, errorMessage)
            });
    }


    // secret: ee6a8728-b936-41c6-9aa4-604292ee63dc
    // value: VSH8Q~FU62g3QhGYsg05qkTIwRqHB66AbeJU5cAZ

    return (
        <Button onClick={() => go()}>Sign in with Outlook</Button>
    )
}