import { Grid, Typography, Card, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FAQ = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    return (
        <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item lg={12} >
                {isDesktopOrLaptop &&
                    <Card style={{ margin: 30, height: '75vh', width: '60vw' }}>
                        <h1 align="center">Using the App</h1>
                        <h3>Random student name picker</h3>
                        <Typography fontSize={20}>Highlight the names of the students you'd like to select from, then click the "Get a random name" button.
                            Note that you do not have to highlight all names. If you only want to pick names from a subsection of the list, only
                            highlight the subsection. Then click the button.
                        </Typography>
                        <h3>Auto-grader</h3>
                        <p><Typography fontSize={20}>Highlight the cells that you would like to auto-score plus one empty column to the right. Then click the
                            "Grade this Range" button. The empty column will populate the scores. Good = 2 points, Fair = 1 point, None = 0 points.
                        </Typography></p>
                        <h3>Email template generator</h3>
                        <Typography fontSize={20}>Click the cell of the name of the student for whom you'd like to generate an email. Then choose what
                            type of email you want to send, e.g. a positive email to a parent. Then click the "Generate email" button.
                        </Typography>
                    </Card>
                }
                {isTabletOrMobile &&
                    <Card style={{ margin: 30, height: '80vh', width: '80vw' }}>
                        <h3 align="center">Using the App</h3>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h4>Random student name picker</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography fontSize={15}>Highlight the names of the students you'd like to select from, then click the "Get a random name" button.
                                    Note that you do not have to highlight all names. If you only want to pick names from a subsection of the list, only
                                    highlight the subsection. Then click the button.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h4>Auto-grader</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography fontSize={15}>Highlight the cells that you would like to auto-score plus one empty column to the right. Then click the
                                    "Grade this Range" button. The empty column will populate the scores. Good = 2 points, Fair = 1 point, None = 0 points.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h4>Email template generator</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography fontSize={15}>Click the cell of the name of the student for whom you'd like to generate an email. Then choose what
                                    type of email you want to send, e.g. a positive email to a parent. Then click the "Generate email" button.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                }
            </Grid>
        </Grid>
    )
}

export default FAQ;