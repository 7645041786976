import { Card, Button } from "@mui/material";
import { useState } from "react";
import { Configuration, OpenAIApi } from 'openai';
import { parse, unparse } from "papaparse";
import { Auth } from "firebase/auth";

const configuration = new Configuration({
    apiKey: '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',
});

const EmailGenerator = () => {
    const [statement, setState] = useState("none");
    const [response, setRes] = useState("");
    const [grades, setGrades] = useState("");
    const [downloadable, setDownloadable] = useState(false);
    const [urlState, setURLState] = useState("");
    let CSVData = [];

    const openai = new OpenAIApi(configuration);

    const prompto2 = (g) => {
        return `write an email to tell Mr. Smith, whose son is a student at my school, 
        that his grades on the last 4 assignments have been ${g}`;
    };

    const generateEmail = async (promptParam) => await openai.createCompletion({
        model: "text-davinci-002",
        prompt: promptParam,
        max_tokens: 160,
        temperature: 0.7,
        frequency_penalty: 0
    });

    const handleCSV = (e) => {
        // fetch('https://canvas.instructure.com/api/v1/users/self', {
        //     method: 'get',
        //     headers: new Headers({
        //         'Authorization': 'Bearer 7~n6uf2afY3Iq4elu7GD7mE7XoqgzfRDfjnAqNGcfI7mh82pwO2MjeGdYAseRLjAKq',
        //         "Access-Control-Allow-Origin": "*"
        //     })
        // }).then((res) => console.log(res));

        // Passing file data (event.target.files[0]) to parse using Papa.parse
        parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                CSVData = results.data;
                CSVData.forEach((item) => {
                    delete item.ID;
                    delete item["SIS Login ID"];
                    // delete item.Student;
                    // use this to insert the generated email

                    // convert the line to an array
                    const line1 = Object.values(item);

                    // USE
                    // convert the array to a string
                    // let line1String = "";
                    // line1.forEach((grade, index) => {
                    //     if (index !== 0) {
                    //         line1String = line1String.concat(", ", grade);
                    //     } else {
                    //         line1String = grade;
                    //     }
                    // });

                    // USE
                    // generateEmail(prompto2(line1String)).then((res) => {
                    //     console.log(line1String + ": " + res.data.choices[0].text);
                    // });
                });
                console.log(CSVData)
                // CSVData = unparse(CSVData);
                // var CSVBlob = new Blob([CSVData], { type: 'text/csv;charset=utf-8;' });
                // setURLState(window.URL.createObjectURL(CSVBlob));
                // setDownloadable(true);
            },
        });
    };

    const createAMessage = async () => {
        const message = {
            subject: 'Happy Birthday, Joe!',
            importance: 'Low',
            body: {
                contentType: 'HTML',
                // content: 'They were <b>awesome</b>!'
                content: `<!DOCTYPE html>
                <html lang="en">
                    <head>
                        <style>
                        h1 {
                            transition: transform .7s ease-in-out;
                            transform: rotate(360deg);
                          }
                          
                          h1:hover {
                            
                          }
                        </style>
                    </head>
                    <body>
                        <h1>Happy Birthday!</h1>
                    </body>
                </html>`
            },
            toRecipients: [
                {
                    emailAddress: {
                        address: 'jevoneshaw@yahoo.com'
                    }
                }
            ]
        };

        const response = await fetch("https://graph.microsoft.com/v1.0/me/messages", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + "EwBwA8l6BAAUkj1NuJYtTVha+Mogk+HEiPbQo04AAdMgIITNM8SyVYhPvlSZ3ZXnrnEgjS3PR9Ah/nRw7ASZ8hf8oMviWqJLahq5UTAGUf5LDeeF82EGeSS4/UkYnydmiasgZdy5xhFCnT9Db//nXJkS6sB//9d0i5oqbi1IfDesEyHT8b6d/a9PAjoMCLnkbQzWvABN9bzsEfn1x0WdLFb/9x9Z7OwualqVT3gZhSkE98HW8+PKtXMXMDv3kKy71K7GMhGpwSPEVTE82lEP7CZaVIPkk5P4kZfkveit/4Qw1pPZDAnABZQxqgupsZKwr3SdMD8qJp9renUflN8pqnv1Ll+UZ1P6+Jf+/KGLavgCWNPTHvTpLP3trKpfgHgDZgAACAZqgGNPJC+DQAJ5qlHp68YvTmCJrA5JeAGfuIRvzY7ApZW192lN6fAiPu3MxpLg3YX3EB2MduYM/kvb1Ne2YxIWkpbG9dRC0LVbSeX7fcIwiJEjEI0TvTqhjPGkYD5+scOA21v8DyEDqJ5itqIl7/9/9nqX+qGLGckbjY0CaMTJ/3PMXwgeWFHr9nNG3sa3li48Ff7TG5dmLQ62Rn8Z5+gwK4aybvwR7FS/WtH+qsBLeJ2H1DTXKx1eKWgpsQiJAKA555CrFqqmIAYbbmVyiyqbqaz1qubUzphdDNvASkvlXq2DmHYyM7mBfZ68tkX2NPnr18Ry91M01njkqNxy75OeoxCK7iFCKNv+qyDRZiSlEGI9KklDcWNiGzLU8aY4omoqu0qGc/BBbMhikMQaN6nZfZ/6Lp/jfR4N9NyyMZKvtobR5M6T2BgAjGom2rNg3nUwmzk0B4Xqj2qvU4soVi3LPxT9lZEHG9u2YKNGQ82dyi2yjm+wTtBj9YDnlux5R+7QUr8861GvE6GEHxlxnTH41xlTU2uJ8SGTMdJM0JJ5UW1GbWofGZgR6+q2WK6ugaXv4W7JByXX+58xJkF0IfUVIamhNLdhhNMMeDWUQkepI1xJrKKpyejDNjNSWpdxlo4t270VTU0VI6f9G9F0NF4DdHHyUVnqGsoiGT1toGeIFLJtw4NK5+NHEnjwzYnHbTPTtl4Tt3BUF/vxtZBF4WJP1g6sD+lS/y8X4ftlEUWIycHvmnRSFHQUct6GtD0Piy2Lln8RJmzKrimFAg==",
                'Authorization': 'Bearer ' + localStorage.getItem("token")

            },
            body: JSON.stringify(message)
        });

        return response.json();
    };

    const handleChange = (e) => {
        setState(e.target.value);
    };

    return (
        <Card style={{ margin: 30, height: '60vh' }} align="center">
            {/* <TextField multiline={true} value={statement} onChange={handleChange}></TextField> */}
            <br />
            <button onClick={() => generateEmail(prompto2).then((res) => {
                console.log(res.data.choices[0].text)
                console.log(prompto2)
                setRes(res.data.choices[0].text)
            })}>Go</button>
            <div>{response}</div>
            {downloadable === true ? <Button download={"grades_with_emails"} href={urlState} target="_blank">Download</Button> :
                <div></div>
            }
            <div>
                {/* File Uploader */}
                <input
                    type="file"
                    name="file"
                    accept=".csv"
                    style={{ display: "block", margin: "10px auto" }}
                    onChange={handleCSV}
                />
            </div>
            <Button onClick={() => createAMessage()}>Create a Message</Button>
        </Card>
    )
};


export default EmailGenerator;