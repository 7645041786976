import { Grid, Button, Card, Typography } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Navbar from "../components/NavigationBar";
import { LoggedInNavbar } from "../components/NavigationBar";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import EmailGenerator from "../components/EmailGenerator";
import Timer from "../components/CountdownTimer";
import { Element } from "react-scroll";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, addDoc, collection, onSnapshot, doc, getDoc, getDocs } from "firebase/firestore";
import firebaseConfig from "../firebaseConfig";
import { useEffect, useState } from "react";
import { getAuth, signInWithRedirect, GoogleAuthProvider, signOut } from "firebase/auth";
import { useMediaQuery } from 'react-responsive';


const HomePage = (props) => {
    const app = initializeApp(firebaseConfig);
    const functions = getFunctions(app);
    const db = getFirestore(app);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });


    const createAccount = () => {
        signInWithRedirect(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    };

    const goToPaymentPage = async () => {
        const docRef = await addDoc(collection(db, `customers/${props.userID}/checkout_sessions`), {
            price: 'price_1LVd6hBj0EXplfITkHGqHQvf',
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });
        onSnapshot(docRef, (doc) => {
            const { error, url } = doc.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });

    };

    const goToAccountPage = async () => {
        const funcRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

        const { data } = await funcRef({
            returnUrl: window.location.origin,
            locale: "auto", // Optional, defaults to "auto"
        });
        window.location.assign(data.url);
    };

    useEffect(() => {

        // get the document to see if the customer has an active subscription or not
        const querySnap = async () => await getDocs(collection(db, `customers/${props.userID}/subscriptions`));
        querySnap().then((result) => {
            result.forEach((document) => {
                console.log(document.id, "=", document.data());
            });
        });
    }, [props.userID]);

    if (props.userID) {
        return (
            <>
                <LoggedInNavbar /> 
                <Grid container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '50vh' }}
                >
                    {/* <Grid item lg={6} sm={12} md={6} >
                        <Card style={{ margin: 30, height: '45vh' }} align="center">
                            <h1>You are now logged in!</h1>
                            <Button onClick={() => goToAccountPage()}>Manage your account</Button>
              
                            <Button onClick={() => goToPaymentPage()}>Start your free trial</Button>
                        </Card>
                    </Grid> */}
                    <Grid item lg={6} sm={12} md={6} >
                        <EmailGenerator />
                    </Grid>
                </Grid>
                {/* <FAQ /> */}
            </>
        )
    } else {
        return (
            <>
                <Navbar />
                <h1 align="center">Our website is currently under construction. We'll be ready soon!</h1>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}>
                    {/* <Grid item lg={12}> */}
                    {isDesktopOrLaptop &&
                        <Card style={{ margin: 30, height: '60vh', width: '68vw' }} align="center">
                            <h1 align="center">CYB-Participation is your new favorite classroom tool.</h1>
                            <Typography fontSize={20}>With tools for name picking, auto scoring, and email template generation,
                                CYB is your new best friend for classroom participation. This extension only works with the free template
                                on TPT, so make sure download the Google Sheet template from there.
                            </Typography>
                            <br /><br />
                            <Button onClick={() => createAccount()} variant="outlined" style={{ color: "black", borderColor: "#88d4f7" }}>Start your free trial<ArrowRightIcon style={{ color: "#88d4f7" }} /></Button>
                        </Card>
                    }
                    {isTabletOrMobile &&
                        <Card style={{ margin: 30, height: '60vh', width: '68vw' }} align="center">
                            <h3 align="center">CYB-Participation is your new favorite classroom tool.</h3>
                            <Typography fontSize={15}>With tools for name picking, auto scoring, and email template generation,
                                CYB is your new best friend for classroom participation. This extension only works with the free template
                                on TPT, so make sure download the Google Sheet template from there.
                            </Typography>
                            <br /> <br />
                            <Button onClick={() => createAccount()} variant="outlined" style={{ color: "black", borderColor: "#88d4f7" }}>Start your free trial<ArrowRightIcon style={{ color: "#88d4f7" }} /></Button>
                        </Card>
                    }
                    {/* </Grid> */}
                </Grid>
                <Element name="pricing"><Pricing /></Element>
                <Element name="FAQ"><FAQ /></Element>
            </>
        )
    }
}

export default HomePage;